<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">Empresas</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard' }">B2B</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Empresas</li>
        </ol>
      </nav>
    </div>
    <div class="col-12 grid-margin stretch-card medicos">
      <div class="card card-statistics">
        <div class="row">
          <div class="card-col col-xl-4 col-lg-6 col-md-6 col-6 border-right">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                <i class="mdi mdi-account-box-multiple text-info mr-0 mr-sm-4 icon-lg"></i>
                <div class="wrapper text-center text-sm-left">
                  <p class="card-text mb-0">Empresas</p>
                  <div class="fluid-container">
                  <h3 class="mb-0 font-weight-medium">{{ totalRows | numberFilter }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-col col-xl-4 col-lg-6 col-md-6 col-6 border-right">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                <i class="mdi mdi mdi-phone-classic text-success mr-0 mr-sm-4 icon-lg"></i>
                <div class="wrapper text-center text-sm-left">
                  <p class="card-text mb-0">Telefones</p>
                  <div class="fluid-container">
                  <h3 class="mb-0 font-weight-medium">{{ totalContatos.telefones | numberFilter }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-col col-xl-4 col-lg-6 col-md-6 col-6 border-right">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                <i class="mdi mdi-mail-ru text-warning mr-0 mr-sm-4 icon-lg"></i>
                <div class="wrapper text-center text-sm-left">
                  <p class="card-text mb-0">Emails</p>
                  <div class="fluid-container">
                  <h3 class="mb-0 font-weight-medium">{{ totalContatos.emails | numberFilter }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card>
          <template #header>
            <empresa-filtros></empresa-filtros>
          </template>

          <b-skeleton-wrapper :loading="loadingTotal">
            <template #loading>
              <b-skeleton></b-skeleton>
            </template>
            <b-row>
              <b-col md="3">
                <h4 class="page-title">
                  {{ totalRows | numberFilter }}
                  <small class="text-muted"> empresas encontradas </small>
                </h4>
              </b-col>
              <b-col md="4">
                <b-dropdown text="Exportar" variant="primary">
                  <template #button-content>
                    <b-spinner v-if="exporting" small type="grow"></b-spinner>
                    <b-icon v-else icon="download" aria-hidden="true"></b-icon>
                    Exportar
                  </template>
                  <b-dropdown-item @click="download('ods')"
                    ><i class="fa fa-file-excel-o"></i> Open Document Format
                    (.ODS)
                  </b-dropdown-item>
                  <b-dropdown-item @click="download('xlsx')">
                    <i class="fa fa-file-excel-o"></i> Microsoft Excel
                    (.XLSX)</b-dropdown-item
                  >
                  <b-dropdown-item @click="download('csv')">
                    <i class="fa fa-file-excel-o"></i> Comma-Separated Values
                    (.CSV)</b-dropdown-item
                  >
                  <b-dropdown-item @click="download('pdf')">
                    <i class="fa fa-file-pdf-o"></i> Portable Document Format
                    (.PDF)</b-dropdown-item
                  >
                </b-dropdown>
              </b-col>
              <b-col md="5">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="md"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-skeleton-wrapper>

          <b-table
            ref="selectableTable"
            show-empty
            stacked="md"
            :items="search"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="empresaFiltros"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :selectable="true"
            select-mode="multi"
            selected-variant="dark"
            responsive
            :busy="loadingTable"
          >
            <template #table-busy>
              <div class="text-center text-white my-2 loadding-white-mode">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>

            <template #head(selected)>
              <b-form-checkbox size="md" @change="selectAllRows">
              </b-form-checkbox>
            </template>

            <template #cell(selected)="row">
              <b-form-checkbox
                size="md"
                :checked="row.rowSelected"
                @change="selectRow(row)"
              >
              </b-form-checkbox>
            </template>

            <template #cell(cnpj)="row">
              <span class="text-wrap">
                {{ row.item.cnpj | cnpjFilter }}
                <b-button
                  v-if="canCopy"
                  @click="copy(row.item.cnpj)"
                  class="btn-link bg-transparent border-0 btn-icon"
                  v-b-tooltip
                  title="Copiar"
                  ><i class="mdi mdi mdi-content-copy"></i
                ></b-button>
                <br />
                <template v-if="row.item.nome_fantasia">
                  <strong>{{ row.item.nome_fantasia }}</strong> <br />
                  <template v-if="row.item.empresa_base">
                    <small>({{ row.item.empresa_base.razao_social }})</small>
                  </template>
                </template>
                <template v-else-if="row.item.empresa_base">
                  <strong>{{ row.item.empresa_base.razao_social }}</strong>
                </template>
              </span>
            </template>

            <template #cell(endereco)="row">
              <span class="text-wrap">
                {{ row.item.cep | cepFilter }} <br />
                {{ row.item.tipo_logradouro }} {{ row.item.logradouro }}
                {{ row.item.numero }} - {{ row.item.bairro }}<br />
                {{ row.item.municipio.nome }}, {{ row.item.uf }}
              </span>
            </template>

            <template #cell(setor_capital)="row">
              <span class="text-wrap">
                <template v-if="row.item.cnae">
                  {{ row.item.cnae.secao }}-{{ row.item.cnae.cnae }} <br />
                  {{ row.item.cnae.denominacao }} <br />
                </template>
                <template v-if="row.item.capital_social > 0">
                  R$ {{ row.item.capital_social }}
                </template>
              </span>
            </template>

            <template #cell(situacao)="row">
              <label
                class="badge"
                :class="{
                  'badge-danger': row.item.situacao_cadastral != 2,
                  'badge-primary': row.item.situacao_cadastral == 2,
                }"
                >{{ row.item.empresa_situacao.descricao }}</label
              >
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="px-4 py-1"
                :to="{ name: 'EmpresaView', params: { id: row.item.id } }"
              >
                Visualizar
              </b-button>
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EmpresaFiltros from "@/components/EmpresaFiltros.vue";
import EmpresaService from "../../services/empresa.service";

export default {
  name: "Empresas",
  components: {
    EmpresaFiltros,
  },
  data() {
    return {
      fields: [
        { key: "selected" },
        {
          key: "cnpj",
          label: "CNPJ e Nome",
          sortable: false,
        },
        {
          key: "endereco",
          label: "Endereço",
          sortable: false,
        },
        {
          key: "setor_capital",
          label: "Setor e Capital Social",
          sortable: false,
        },
        { key: "situacao", label: "Situação" },
        { key: "actions", label: "Ações" },
      ],
      totalRows: 0,
      totalContatos: {
        telefones: 0,
        emails: 0,
        enderecos: 0,
      },
      currentPage: 1,
      perPage: 20,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      loadingTable: false,
      loadingTotal: false,
      canCopy: false,
      exporting: false,
    };
  },
  created() {
    this.canCopy = !!navigator.clipboard;
  },
  computed: {
    ...mapState(["empresaFiltros"]),
    ...mapGetters(["empresaFiltrosQuery"]),
  },
  mounted() {
    this.updateTotalRows();
    this.updateTotalContatos();
  },
  watch: {
    // Atualizar contador somente se houver mudancas nos filtros
    empresaFiltrosQuery: function () {
      this.currentPage = 1;
      this.updateTotalRows();
      this.updateTotalContatos();
    },
  },
  methods: {
    search(ctx) {
      let params = {
        ...this.empresaFiltrosQuery,
        page: ctx.currentPage,
        perPage: ctx.perPage,
        orderBy: ctx.sortBy,
        sort: ctx.sortDesc ? "desc" : "asc",
      };

      this.loadingTable = true;

      const promise = EmpresaService.getAll(params);

      return promise
        .then((response) => {
          this.loadingTable = false;
          const items = response.data.data;
          // this.totalRows = response.data.total;
          // console.log(response);
          return items || [];
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },
    updateTotalRows() {
      let params = {
        ...this.empresaFiltrosQuery,
      };
      this.loadingTotal = true;
      EmpresaService.total(params)
        .then((response) => {
          this.loadingTotal = false;
          this.totalRows = response.data.total;
        })
        .catch(() => {
          this.loadingTotal = false;
          this.totalRows = 0;
        });
    },
    selectAllRows(checked) {
      if (checked) {
        this.$refs.selectableTable.selectAllRows();
      } else {
        this.$refs.selectableTable.clearSelected();
      }
    },
    updateTotalContatos() {
      let params = {
        ...this.empresaFiltrosQuery,
      };
      EmpresaService.totalContatos(params)
        .then((response) => {
          this.totalContatos = response.data;
        })
        .catch(() => {
          this.totalContatos = {
            telefones: 0,
            emails: 0,
            enderecos: 0,
          };
        });
    },
    selectRow(row) {
      if (row.rowSelected) {
        row.unselectRow();
      } else {
        row.selectRow();
      }
    },
    download(format) {
      let params = this.empresaFiltrosQuery;
      this.exporting = true;
      const promise = EmpresaService.download(format, params);
      return promise
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "empresas." + format;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {})
        .then(() => {
          this.exporting = false;
        });
    },
    async copy(s) {
      await navigator.clipboard.writeText(s);
      this.$bvToast.toast("CNPJ copiado para clipboard", {
        title: "Sucesso!",
        autoHideDelay: 1000,
        toaster: "b-toaster-bottom-left",
        variant: "primary",
      });
    },
  },
};
</script>
