import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/municipios";

class MunicipioService {

    getAll(params) {
        return axios.get(API_URL, {
            params: params,
            headers: authHeader()
        });
    }
}

export default new MunicipioService();
