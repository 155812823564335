import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/empresas";

class EmpresaService {

    getAll(params) {
        return axios.post(API_URL, params, {
            headers: authHeader()
        });
    }

    total(params) {
        return axios.post(API_URL + '/total', params, {
            headers: authHeader()
        });
    }

    get(id) {
        return axios.get(API_URL + '/' + id, { headers: authHeader() });
    }

    download(format, params) {
        return axios.post(API_URL + '/download/' + format, params, {
            responseType: 'arraybuffer',
            headers: authHeader()
        });
    }

    totalContatos(params) {
        return axios.post(API_URL + '/totalContatos', params, {
            headers: authHeader()
        });
    }
    
}

export default new EmpresaService();
