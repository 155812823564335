 <template>
  <div>
    <b-row>
      <b-col>
        <label class="sr-only">Atividade Econômica</label>
        <multiselect
          :value="empresaFiltros.cnaes"
          @input="atualizarCnaesAction"
          :options="cnaes"
          :multiple="true"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Selecionar CNAE"
          :custom-label="cnaeLabel"
          track-by="id"
          selectLabel="Enter para selecionar"
          selectedLabel="Selecionado"
          deselectLabel="Enter para remover"
          @open="carregarCnaes"
          :loading="carregandoCnaes"
        >
          <template slot="noOptions">Lista está vazia.</template>
        </multiselect>
      </b-col>
      <b-col>
        <label class="sr-only">Estados</label>
        <multiselect
          :value="empresaFiltros.estados"
          @input="atualizarEstados"
          :options="estados"
          :multiple="true"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="true"
          placeholder="Selecionar estados "
          label="nome"
          track-by="id"
          selectLabel="Enter para selecionar"
          selectedLabel="Selecionado"
          deselectLabel="Enter para remover"
        >
          <template slot="noOptions">Lista está vazia.</template>
        </multiselect>
      </b-col>
      <b-col>
        <label class="sr-only">Nome Fantasia</label>

        <b-form-input
          :value="empresaFiltros.q"
          @update="atualizarPalavraChaveAction"
          type="search"
          id="filterInput"
          placeholder="Nome da Empresa"
          debounce="500"
          style="height: 100%"
        ></b-form-input>
      </b-col>
      <b-col>
        <b-button
          v-b-toggle.filtros-avancados
          variant="primary"
          class="h-100"
          block
          >Pesquisa Avançada</b-button
        >
      </b-col>
    </b-row>

    <b-sidebar
      id="filtros-avancados"
      title="Pesquisa Avançada"
      bg-variant="dark"
      text-variant="light"
      width="400px"
      shadow
      right
    >
      <div class="px-3 py-2">
        <div class="accordion accordion-solid-header" role="tablist">
          <b-card no-body v-if="filtroContato">
            <b-card-header header-tag="header" role="tab">
              <a href="javascript:void(0);" v-b-toggle.accordion-contatos>
                Perfil do Contato
              </a>
            </b-card-header>
            <b-collapse
              id="accordion-contatos"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-form-group label="Departamento">
                  <multiselect
                    :value="empresaFiltros.departamentos"
                    @input="atualizarDepartamentosAction"
                    :options="departamentos"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Depto."
                    label="nome"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                    @open="carregarDepartamentos"
                    :loading="carregandoDepartamentos"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <b-form-group label="Nível Hierárquico">
                  <multiselect
                    :value="empresaFiltros.nivelHierarquicos"
                    @input="atualizarNivelHierarquicosAction"
                    :options="nivelHierarquicos"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Nível"
                    label="nome"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                    @open="carregarNivelHierarquicos"
                    :loading="carregandoNivelHierarquicos"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <b-form-group label="Meios de Contato">
                  <b-form-checkbox
                    value="1"
                    :unchecked-value="null"
                    @input="atualizarContatoComTelefoneAction"
                    :switch="false"
                  >
                    Telefone
                  </b-form-checkbox>
                </b-form-group>

              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body>
            <b-card-header header-tag="header" role="tab">
              <a href="javascript:void(0);" v-b-toggle.accordion-1> Região </a>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-form-group label="Estados">
                  <multiselect
                    :value="empresaFiltros.estados"
                    @input="atualizarEstados"
                    :options="estados"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="true"
                    placeholder="Selecionar estados"
                    label="nome"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <b-form-group label="Municípios">
                  <multiselect
                    :value="empresaFiltros.municipios"
                    @input="atualizarMunicipiosAction"
                    :options="municipios"
                    @search-change="findMunicipios"
                    :loading="carregandoMunicipios"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar municípios"
                    :custom-label="nomeUf"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                  >
                    <template slot="noOptions">Digite para pesquisar.</template>
                  </multiselect>
                </b-form-group>

                <b-form-group label="Bairro" label-for="bairroInput">
                  <b-input-group size="md">
                    <b-form-input
                      :value="empresaFiltros.bairro"
                      @update="atualizarBairroAction"
                      type="search"
                      id="bairroInput"
                      placeholder="Inserir Bairro"
                      debounce="500"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>

                <b-form-group label="Logradouro" label-for="logradouroInput">
                  <b-input-group size="md">
                    <b-form-input
                      :value="empresaFiltros.logradouro"
                      @update="atualizarLogradouroAction"
                      type="search"
                      id="logradouroInput"
                      placeholder="Inserir Logradouro"
                      debounce="500"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>

                <b-form-group label="CEP" label-for="cepInput">
                  <b-input-group size="md">
                    <b-form-input
                      :value="empresaFiltros.cep"
                      @update="atualizarCepAction"
                      type="search"
                      id="cepInput"
                      placeholder="Inserir CEP"
                      debounce="500"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body>
            <b-card-header header-tag="header" role="tab">
              <a href="javascript:void(0);" v-b-toggle.accordion-2> Setor </a>
            </b-card-header>
            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-form-group label="Atividade Econômica">
                  <multiselect
                    :value="empresaFiltros.cnaes"
                    @input="atualizarCnaesAction"
                    :options="cnaes"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar CNAE"
                    :custom-label="cnaeLabel"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                    @open="carregarCnaes"
                    :loading="carregandoCnaes"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <b-form-group label="Nome Fantasia" label-for="filterInput">
                  <b-input-group size="md">
                    <b-form-input
                      :value="empresaFiltros.q"
                      @update="atualizarPalavraChaveAction"
                      type="search"
                      id="filterInput"
                      placeholder="Inserir Palavras Chave"
                      debounce="500"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body>
            <b-card-header header-tag="header" role="tab">
              <a href="javascript:void(0);" v-b-toggle.accordion-porte>
                Porte
              </a>
            </b-card-header>
            <b-collapse
              id="accordion-porte"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-form-group label="Faturamento Presumido">
                  <b-input-group prepend="De:">
                    <b-form-select
                      :value="empresaFiltros.faturamentoMin"
                      @input="atualizarFaturamentoMinAction"
                      :options="capitalSocialData"
                    >
                    </b-form-select>
                  </b-input-group>
                  <b-input-group prepend="Até">
                    <b-form-select
                      :value="empresaFiltros.faturamentoMax"
                      @input="atualizarFaturamentoMaxAction"
                      :options="capitalSocialData"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>

                <b-form-group label="Quantidade de Funcionários">
                  <b-input-group prepend="De:">
                    <b-form-select
                      :value="empresaFiltros.funcionariosMin"
                      @input="atualizarFuncionariosMinAction"
                      :options="funcionariosOptions"
                    >
                    </b-form-select>
                  </b-input-group>
                  <b-input-group prepend="Até">
                    <b-form-select
                      :value="empresaFiltros.funcionariosMax"
                      @input="atualizarFuncionariosMaxAction"
                      :options="funcionariosOptions"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  label="Enquadramento de Porte"
                  label-for="enquadramento-porte-checkbox"
                >
                  <b-form-checkbox-group
                    id="enquadramento-porte-checkbox"
                    @input="atualizarPortesAction"
                    :options="portes"
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body>
            <b-card-header header-tag="header" role="tab">
              <a href="javascript:void(0);" v-b-toggle.accordion-3>
                Perfil da Empresa
              </a>
            </b-card-header>
            <b-collapse
              id="accordion-3"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-form-group label="Situação Receita Federal">
                  <multiselect
                    :value="empresaFiltros.empresaSituacaos"
                    @input="atualizarEmpresaSituacaosAction"
                    :options="empresaSituacaos"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Situação"
                    label="text"
                    track-by="value"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>
                <b-form-group label="Data de Abertura">
                  <b-form-datepicker
                    id="dataAberturaInicioInput"
                    :value="empresaFiltros.dataAberturaInicio"
                    @input="atualizarDataAberturaInicioAction"
                    class="mb-2"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="pt-br"
                    placeholder="De"
                    label-help="Use as teclas de cursor para navegar pelas datas do calendário"
                  ></b-form-datepicker>

                  <b-form-datepicker
                    id="dataAberturaFimInput"
                    :value="empresaFiltros.dataAberturaFim"
                    @input="atualizarDataAberturaFimAction"
                    class="mb-2"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="pt-br"
                    placeholder="Até"
                    label-help="Use as teclas de cursor para navegar pelas datas do calendário"
                  ></b-form-datepicker>
                </b-form-group>

                <b-form-group label="Capital Social">
                  <b-input-group prepend="De:">
                    <b-form-select
                      :value="empresaFiltros.capitalSocialMin"
                      @input="atualizarCapitalSocialMinAction"
                      :options="capitalSocialData"
                    >
                    </b-form-select>
                  </b-input-group>
                  <b-input-group prepend="Até">
                    <b-form-select
                      :value="empresaFiltros.capitalSocialMax"
                      @input="atualizarCapitalSocialMaxAction"
                      :options="capitalSocialData"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>

                <b-form-group label="Matriz e Filiais">
                  <b-form-checkbox
                    value="1"
                    :unchecked-value="null"
                    @input="atualizarMatrizFilialAction"
                    :switch="false"
                  >
                    Somente Matriz
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group
                  v-if="empresaFiltros.matrizFilial == 1"
                  label="Quantidade de Filiais"
                >
                  <b-input-group prepend="De:">
                    <b-form-select
                      :value="empresaFiltros.filiaisMin"
                      @input="atualizarFiliaisMinAction"
                      :options="filiaisOptions"
                    >
                    </b-form-select>
                  </b-input-group>
                  <b-input-group prepend="Até">
                    <b-form-select
                      :value="empresaFiltros.filiaisMax"
                      @input="atualizarFiliaisMaxAction"
                      :options="filiaisOptions"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>

                <b-form-group label="Natureza Jurídica">
                  <multiselect
                    :value="empresaFiltros.naturezaJuridicas"
                    @input="atualizarNaturezaJuridicasAction"
                    :options="naturezaJuridicas"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Natureza"
                    :custom-label="naturezaJuridicaLabel"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                    @open="carregarNaturezaJuridicas"
                    :loading="carregandoNaturezaJuridicas"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body>
            <b-card-header header-tag="header" role="tab">
              <a href="javascript:void(0);" v-b-toggle.accordion-cnpj> CNPJ </a>
            </b-card-header>
            <b-collapse
              id="accordion-cnpj"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-form-group label-for="cnpjInput">
                  <template slot="label"
                    >CNPJ
                    <i
                      class="mdi mdi-information-outline"
                      v-b-tooltip.hover
                      title="Pesquise por lista de CNPJs, separando um para cada linha."
                    ></i
                  ></template>
                  <b-input-group size="md">
                    <b-form-textarea
                      :value="empresaFiltros.cnpjs"
                      @update="atualizarCNPJsAction"
                      id="cnpjInput"
                      placeholder="CNPJ ou lista de CNPJs"
                      debounce="500"
                      rows="5"
                      max-rows="10"
                    ></b-form-textarea>
                  </b-input-group>
                </b-form-group>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import EstadoService from "../services/estado.service";
import MunicipioService from "../services/municipio.service";
import CnaeService from "../services/cnae.service";
import NaturezaJuridicaService from "../services/naturezajuridica.service";
import DepartamentoService from "../services/departamento.service";
import NivelHierarquicoService from "../services/nivelhieararquico.service";

export default {
  name: "EmpresaFiltros",
  components: {
    // VueRangeSlider,
  },
  data() {
    return {
      estados: [],
      municipios: [],
      carregandoMunicipios: false,
      cnaes: [],
      carregandoCnaes: false,
      capitalSocialData: [
        { value: null, text: "-" },
        { value: 0, text: "R$ 0" },
        { value: 80000, text: "R$ 80.000" },
        { value: 360000, text: "R$ 360.000" },
        { value: 480000, text: "R$ 4.800.000" },
        { value: 20000000, text: "R$ 20.000.000" },
        { value: 50000000, text: "R$ 50.000.000" },
        { value: 100000000, text: "R$ 100.000.000" },
        { value: 300000000, text: "R$ 300.000.000" },
        { value: 700000000, text: "R$ 700.000.000" },
        { value: 4000000000, text: "R$ 4.000.000.000" },
      ],
      filiaisOptions: [
        { value: null, text: "-" },
        0,
        50,
        100,
        200,
        300,
        400,
        500,
        1000,
      ],
      funcionariosOptions: [
        { value: null, text: "-" },
        0,
        10,
        50,
        200,
        500,
        1000,
        5000,
        10000,
      ],
      portes: [
        { text: "Não Informado", value: 0 },
        { text: "Micro Empresa", value: 1 },
        { text: "Empresa de Pequeno Porte", value: 3 },
        { text: "Demais", value: 5 },
      ],
      naturezaJuridicas: [],
      carregandoNaturezaJuridicas: false,
      departamentos: [],
      carregandoDepartamentos: false,
      nivelHierarquicos: [],
      carregandoNivelHierarquicos: false,
      empresaSituacaos: [
        { text: "Ativa", value: 2 },
        { text: "Nula", value: 1 },
        { text: "Suspensa", value: 3 },
        { text: "Inapta", value: 4 },
        { text: "Baixada", value: 8 },
      ],
    };
  },
  props: {
    filtroContato: Boolean,
  },
  computed: {
    ...mapState(["empresaFiltros"]),
    ...mapGetters(["listaEstados"]),
  },
  mounted() {
    EstadoService.getAll().then((response) => {
      this.estados = response.data;
    });
  },
  methods: {
    ...mapActions([
      "atualizarPalavraChaveAction",
      "atualizarEstadosAction",
      "atualizarMunicipiosAction",
      "atualizarBairroAction",
      "atualizarLogradouroAction",
      "atualizarCepAction",
      "atualizarCnaesAction",
      "atualizarDataAberturaInicioAction",
      "atualizarDataAberturaFimAction",
      "atualizarCapitalSocialMinAction",
      "atualizarCapitalSocialMaxAction",
      "atualizarMatrizFilialAction",
      "atualizarContatoComTelefoneAction",
      "atualizarFiliaisMinAction",
      "atualizarFiliaisMaxAction",
      "atualizarOpcaoSimplesAction",
      "atualizarPortesAction",
      "atualizarNaturezaJuridicasAction",
      "atualizarDepartamentosAction",
      "atualizarNivelHierarquicosAction",
      "atualizarFaturamentoMinAction",
      "atualizarFaturamentoMaxAction",
      "atualizarFuncionariosMinAction",
      "atualizarFuncionariosMaxAction",
      "atualizarCNPJsAction",
      "atualizarEmpresaSituacaosAction",
    ]),
    atualizarEstados(value) {
      this.atualizarEstadosAction(value);
      if (this.listaEstados.length) {
        this.findMunicipios(null);
      } else {
        this.municipios = [];
      }
    },
    findMunicipios(query) {
      this.carregandoMunicipios = true;
      let params = {
        ufs: this.listaEstados,
        nome: query,
      };
      MunicipioService.getAll(params).then((response) => {
        this.municipios = response.data;
        this.carregandoMunicipios = false;
      });
    },
    nomeUf(municipio) {
      return `${municipio.nome} (${municipio.uf})`;
    },
    cnaeLabel(cnae) {
      return `${cnae.secao}${cnae.cnae ? "-" + cnae.cnae : ""} - ${
        cnae.denominacao
      }`;
    },
    carregarCnaes() {
      if (this.cnaes.length == 0) {
        this.carregandoCnaes = true;
        CnaeService.getAll().then((response) => {
          this.cnaes = response.data;
          this.carregandoCnaes = false;
        });
      }
    },
    naturezaJuridicaLabel(naturezaJuridica) {
      return `${naturezaJuridica.codigo} - ${naturezaJuridica.nome}`;
    },
    carregarNaturezaJuridicas() {
      if (this.naturezaJuridicas.length == 0) {
        this.carregandoNaturezaJuridicas = true;
        NaturezaJuridicaService.getAll().then((response) => {
          this.naturezaJuridicas = response.data;
          this.carregandoNaturezaJuridicas = false;
        });
      }
    },
    moneyFormatter(v) {
      if (v !== undefined)
        return `R$ ${v.toLocaleString()}${v == 1000000 ? "+" : ""}`;
      else return "";
    },
    carregarDepartamentos() {
      if (this.departamentos.length == 0) {
        this.carregandoDepartamentos = true;
        DepartamentoService.getAll().then((response) => {
          this.departamentos = response.data;
          this.carregandoDepartamentos = false;
        });
      }
    },
    carregarNivelHierarquicos() {
      if (this.departamentos.length == 0) {
        this.carregandoNivelHierarquicos = true;
        NivelHierarquicoService.getAll().then((response) => {
          this.nivelHierarquicos = response.data;
          this.carregandoNivelHierarquicos = false;
        });
      }
    },
  },
};
</script>
